import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Work from './components/Work';
import Client from './components/Client';
import Expertise from './components/Expert';
import Whoweare from './components/Whoweare';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTop from "react-scroll-to-top";
import Feed from './components/Feed.js';

function App() {
  return (
    <>
    <Navbar/>
    <Feed/>
    <Home/>
    <Work/>
    <Client/>
    <Expertise/>
    <Whoweare/>
    <Contact/>
    <Footer/>
    <ScrollToTop smooth />
    </>
    );
}

export default App;
