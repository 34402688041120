import React from 'react'

function Footer() {
  return (
    <div className="footer-wrapper">
        <div className="container">
            <div className="copyright text-center py-3 text-white">© Copyright 2024 Run My Digital | Powered by <a className='text-white' target="_blank" href="https://vartservices.com/">V-Art Services Pvt Ltd</a></div>
        </div>
    </div>
  )
}

export default Footer