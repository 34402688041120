import React from 'react'
import banner1 from '../images/we-think-big_banner.jpg';
import banner2 from '../images/we-think-big_banner2.jpg';
import banner3 from '../images/we-think-big_banner3.jpg';

function Home() {
  return (
    <div className="home-wrapper">
        <section className="banner-wrapper">
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                    <img src={banner1} className="d-block w-100" alt="banner" />
                    </div>
                    <div className="carousel-item">
                    <img src={banner2} className="d-block w-100" alt="banner" />
                    </div>
                    <div className="carousel-item">
                    <img src={banner3} className="d-block w-100" alt="banner" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
        <section className="think-big sec-padd text-white">
    <div className="container">
            <div className="background-text">About Us</div>
        <div className="content text-center">
            <h2 className="section-title">WE THINK BIG</h2>
            <h5 className="title-border mb-3">Web Design & Digital Marketing Company in INDIA</h5> 
            <h6>At RUN MY DIGITAL, our team of innovators and digital marketers in tech and design bring skills above and beyond the ordinary to every project. Our web design and development team is involved in professional B2B and B2C web design and development, mobile applications, digital strategy, user experience, advertising, social media, content management systems, and email marketing initiatives.</h6>       
        </div>        
    </div>
</section>
    </div>
  )
}

export default Home