import React, { useEffect } from 'react';

function Feed() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    script.onload = () => {
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="elfsight-app-ec16dd3c-61ee-4f64-a3c7-6de50013ffd2" ></div>
    </div>
  );
}

export default Feed;
